import React from "react";
import { Box, Checkbox, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useGetBuyerLocationsQuery,
  useGetOrganisationsQuery,
  useGetSalesOrdersQuery,
  useGetSupplierInvoicesQuery,
} from "../../redux/apiSlice";
import OrderViewEntry from "./OrderViewEntry";
import { filterDatesSlice } from "../../redux/filterDatesSlice";
import { useAppSelector } from "../../redux/hooks";
import debounce from "../../utils/debounce";
import { userSlice } from "../../redux/userSlice";
import DataTabs from "../../components/DataTabs/DataTabs";
import InvoiceViewEntry from "./InvoiceViewEntry";
import OrderModal from "./OrderModal";
import { Location, Order } from "../../types";
import { getLocationAndOrgName } from "../../utils/invoiceUtils.ts";

const SalesOrdersView = ({
  checkedOrders,
  handleCheckOrder,
  allOrdersChecked,
  handleCheckAllOrders,
  setTabIndex,
  filterOrders,
  setFilterOrders,
}: {
  checkedOrders: Map<number, boolean>;
  handleCheckOrder: (orderId: number, value: boolean) => void;
  allOrdersChecked: boolean;
  handleCheckAllOrders: (value: boolean) => void;
  setTabIndex: (index: number) => void;
  filterOrders: boolean;
  setFilterOrders: (value: boolean) => void;
}): React.ReactElement => {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { getCurrentLocationId, getCurrentOrganisationId, getCurrentLocation } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const currentLocation = useAppSelector(getCurrentLocation);

  const {
    data: orders,
    error: ordersError,
    isLoading: ordersLoading,
  } = useGetSalesOrdersQuery(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: organisations,
    error: organisationsError,
    isLoading: organisationsLoading,
  } = useGetOrganisationsQuery("");

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetSupplierInvoicesQuery(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: buyerLocations = [],
    error: buyerLocationsError,
    isLoading: isLoadingBuyerLocations,
  } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [locationIdToLocation, setLocationIdToLocation] = useState<
    Map<number, Location>
  >(new Map());

  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    if (buyerLocations && !isLoadingBuyerLocations && !buyerLocationsError) {
      setLocationIdToLocation(
        new Map(buyerLocations.map((loc) => [loc.id, loc])),
      );
    } else {
      setLocationIdToLocation(new Map());
    }
  }, [buyerLocations]);

  const debouncedSetFilterOrders = debounce(
    () => setFilterOrders(!filterOrders),
    300,
  );

  useEffect(() => {
    if (orders && organisations) {
      const sortedOrders = orders.slice().sort((a, b) => {
        if (a.created_at && b.created_at) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        }
        return b.id && a.id ? b.id - a.id : -1;
      });
      if (filterOrders) {
        setOrdersList(
          sortedOrders.filter(
            (order) => filterOrders && order.status !== "DELIVERED",
          ),
        );
      } else {
        setOrdersList(sortedOrders);
      }
    } else {
      setOrdersList([]);
    }
  }, [orders, organisations, filterOrders]);

  return (
    <Box
      p={2}
      my={10}
      mx={[0, 0, 10]}
      bg={"gray.200"}
      border="1px solid"
      borderColor="gray.400"
      rounded="md"
      boxShadow="lg"
      overflow="hidden"
      minH="300px"
    >
      {isOpen && <OrderModal isOpen={isOpen} onClose={onClose} isSalesView />}
      <DataTabs
        onChange={(index) => setTabIndex(index)}
        filterApplied={filterOrders}
        setFilterApplied={debouncedSetFilterOrders}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        data={[
          {
            label: "Orders",
            buttonLabel: "Add Sales Order",
            showFilter: true,
            filterLabel: "Filter delivered orders",
            headers: [
              <Checkbox
                isChecked={allOrdersChecked}
                colorScheme="teal"
                onChange={(e) => {
                  e.stopPropagation();
                  if (handleCheckAllOrders)
                    handleCheckAllOrders(!isAllSelected);
                  setIsAllSelected(!isAllSelected);
                }}
              />,
              "Order ID",
              "Customer",
              "Ordered on",
              "Requested for",
              "Status",
              "Value",
            ],
            isLoading: ordersLoading || organisationsLoading || invoicesLoading,
            entries:
              ordersList &&
              organisations &&
              !ordersLoading &&
              !organisationsLoading
                ? ordersList
                    .slice()
                    .sort((a, b) => b.id - a.id)
                    .map((order, index) => (
                      <OrderViewEntry
                        key={index}
                        itemIndex={index}
                        order={order}
                        organisations={organisations}
                        supplierOrCustomerName={getLocationAndOrgName(
                          order.location,
                        )}
                        isSupplierView={true}
                        showSelect={true}
                        isChecked={checkedOrders.get(order.id) || false}
                        setChecked={(value: boolean) =>
                          handleCheckOrder(order.id, value)
                        }
                      />
                    ))
                : [],
          },
          {
            label: "Invoices",
            headers: [
              "Invoice ID",
              "Order ID",
              "Customer",
              "Invoice Date",
              "Delivery Date",
              "Status",
              "Total",
              "Credit",
              "Balance",
              "Paid",
              "Due",
            ],
            hideButton: true,
            isLoading: invoicesLoading,
            entries:
              invoices && !invoicesLoading
                ? invoices
                    .slice()
                    .sort((a, b) => b.id - a.id)
                    .map((invoice, index) => (
                      <InvoiceViewEntry
                        key={index}
                        itemIndex={index}
                        invoice={invoice}
                        supplierOrCustomerName={getLocationAndOrgName(
                          invoice.location,
                        )}
                        isSupplierView={true}
                        buyer={locationIdToLocation.get(invoice.location_id)}
                        supplierAddress={currentLocation.address}
                      />
                    ))
                : [],
          },
        ]}
      />
      {ordersError && <div>Failed to load orders</div>}
      {organisationsError && <div>Failed to load organisations</div>}
      {invoicesError && <div>Failed to load invoices</div>}
      {(ordersLoading || organisationsLoading || invoicesLoading) && (
        <div>Loading...</div>
      )}
    </Box>
  );
};

export default SalesOrdersView;
