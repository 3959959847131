import { Address, InvoiceItem, Location, OrderItem } from "../types";

export const getItemSalesPrice = (item: OrderItem | InvoiceItem) => {
  return item.discounted_price || item.price;
};

export const getOrderTotal = (orderItem: OrderItem) => {
  return getItemSalesPrice(orderItem) * orderItem.quantity;
};

export const getInvoiceTotal = (invoiceItem: InvoiceItem) => {
  return getItemSalesPrice(invoiceItem) * invoiceItem.invoiced_quantity;
};

export const getInvoiceCredit = (invoiceItem: InvoiceItem) => {
  return (
    invoiceItem.price *
    (invoiceItem.invoiced_quantity - invoiceItem.received_quantity)
  );
};

export const showDecimalOnlyIfPresent = (x: number) =>
  x
    .toFixed(2)
    .replace(/[.,]00$/, "")
    .replace(/0$/, "");

export const getLocationAndOrgName = (buyer?: Location) =>
  buyer
    ? buyer.organisation
      ? buyer.organisation?.name +
        `${buyer.name && buyer.name.length > 0 ? " - " + buyer.name : ""}`
      : buyer.name
    : "";

export const formatCurrency = (value: number | string) => {
  // format currency to string with 2 decimal places and commas
  let currency = Number(value).toFixed(2);
  if (currency === "-0.00") currency = "0.00";
  return currency.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const getAddressArray = (address?: Address) => {
  if (!address) return [];
  return [
    address.street_1,
    address.street_2 || "",
    `${address.city}, ${address.state}`,
    address.postal_code,
    address.country,
  ].filter((x) => x && x.length > 0);
};

export const getInvoiceStatusColor = (
  status: string | undefined,
  isPaymentView: boolean = false,
) => {
  switch (status) {
    case "PENDING":
      return isPaymentView ? "red" : "yellow";
    case "CONFIRMED":
      return isPaymentView ? "red" : "green";
    case "PAID":
      return "teal";
    case "PAID_PARTIAL":
      return "pink";
    case "PAID_BALANCED":
      return "blue";
    case "OVERPAID":
      return "red";
    default:
      return "red";
  }
};

export const getInvoiceStatusLabel = (
  status: string | undefined,
  isPaymentView: boolean = false,
) => {
  switch (status) {
    case "PAID":
      return "PAID";
    case "PAID_PARTIAL":
      return "PARTIAL";
    case "PAID_BALANCED":
      return "BALANCED";
    case "OVERPAID":
      return "OVERPAID";
    default:
      return isPaymentView ? "UNPAID" : status;
  }
};

export const getDiscountPercentage = (item: OrderItem | InvoiceItem) => {
  return item.discounted_price && item.price !== item.discounted_price
    ? ((item.price - item.discounted_price) / item.price) * 100
    : 0;
};
