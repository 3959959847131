import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import {
  useDeleteInventoryItemsFromUnitIdListMutation,
  useGetInventoryItemsQuery,
  usePostInventoryItemListMutation,
  usePostProductMutation,
  usePostProductUnitListMutation,
  useSearchProductsQuery,
} from "../../redux/apiSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { Product, ProductUnit, ProductUnitBase } from "../../types";
import ProductUnitManager from "../../components/ProductUnitManager/ProductUnitManager.tsx";
import { getProductUnitLabel } from "../../utils/productUnitUtils.ts";
import { capitalizeWords } from "../../utils/stringUtils.ts";
import { ProductUnitMgr } from "../../components/ProductUnitManager/types.ts";

interface AddProductModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddProductModal = ({
  isOpen,
  onClose,
}: AddProductModalProps): React.ReactElement => {
  const toast = useToast();
  const [productName, setProductName] = useState<string>("");
  const [variety, setVariety] = useState<string>("");
  const [fullProductName, setFullProductName] = useState<string>("");
  const [unitLabel, setUnitLabel] = useState<string>("");
  const [unitQuantity, setUnitQuantity] = useState<number>(1);
  const [unitMeasure, setUnitMeasure] = useState<string>("lb");
  const [unitSystem, setUnitSystem] = useState<string>("IMPERIAL_US");

  const [postProduct, { isLoading }] = usePostProductMutation();
  const [postProductUnitList, { isLoading: isPostingProductUnits }] =
    usePostProductUnitListMutation();

  const [postInventoryItemList, { isLoading: isPostingInventoryItems }] =
    usePostInventoryItemListMutation();
  const [
    deleteInventoryItemListFromUnitIdList,
    { isLoading: isDeletingInventoryItems },
  ] = useDeleteInventoryItemsFromUnitIdListMutation();

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productUnitMgr, setProductUnitMgr] = useState<ProductUnitMgr | null>(
    null,
  );
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false);

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const [units, setUnits] = useState<(ProductUnit | ProductUnitBase)[]>([]);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [matchInResults, setMatchInResults] = useState<boolean>(false);

  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );

  const { data: inventoryItems } = useGetInventoryItemsQuery(
    {
      organisationId: organisationId,
    },
    {
      skip: !organisationId,
    },
  );

  const { data: searchResults, isFetching: isSearching } =
    useSearchProductsQuery(searchTerm, {
      skip: searchTerm.length < 1,
    });

  useEffect(() => {
    if (inventoryItems && inventoryItems.length > 0) {
      setInventoryUnitIds(
        new Set(inventoryItems.map((item) => item.product_unit_id)),
      );
    }
  }, [inventoryItems]);

  useEffect(() => {
    if (isOpen && selectedProduct) {
      setProductUnitMgr(
        new ProductUnitMgr(
          selectedProduct.product_units,
          inventoryUnitIds || new Set(),
        ),
      );
    }
  }, [selectedProduct, inventoryUnitIds, isOpen]);

  useEffect(() => {
    if (productName.length > 0 && searchResults && searchResults.length > 0) {
      const product = searchResults?.find(
        (p) => p.name.toLowerCase() === fullProductName.toLowerCase(),
      );
      if (product) {
        setMatchInResults(true);
      } else {
        setMatchInResults(false);
      }
    }
  }, [searchResults, fullProductName]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setShowSearchResults(true);
  };

  const handleMeasureChange = (value: string) => {
    setUnitMeasure(value);
    if (["oz", "lb"].includes(value)) {
      setUnitSystem("IMPERIAL_US");
    } else if (value === "unit") {
      setUnitSystem("QUANTITY");
    } else {
      setUnitSystem("METRIC");
    }
  };

  const updateFullProductName = (name: string, variety: string) => {
    const fullName = variety ? `${name} - ${variety}` : name;
    setFullProductName(fullName);
    handleSearch(fullName);
  };

  const handleSelectExistingProduct = (product: Product) => {
    setShowSearchResults(false);
    setSelectedProduct(product);
    const [name, variety] = product.name.split(" - ");
    setProductName(name);
    setVariety(variety || "");

    updateFullProductName(name, variety);

    // Set the available units for the selected product
    if (product.product_units && product.product_units.length > 0) {
      // Set the first unit as default
      const defaultUnit = product.product_units[0];
      setUnitLabel(defaultUnit.unit_label);
      setUnitQuantity(defaultUnit.unit_quantity);
      setUnitMeasure(defaultUnit.unit_measure);
      setUnitSystem(defaultUnit.unit_system);
    }
  };

  const [isAddingNewUnit, setIsAddingNewUnit] = useState(false);

  const handleAddProduct = () => {
    if (!isCreatingProduct) {
      handleProductUnitUpdate();
    } else {
      createNewProduct();
    }
  };

  const handleProductUnitUpdate = () => {
    if (
      isLoading ||
      isPostingProductUnits ||
      isPostingInventoryItems ||
      isDeletingInventoryItems
    ) {
      return;
    }
    if (!productUnitMgr || !selectedProduct) {
      Sentry.captureException(
        "Product unit manager or selected product not set.",
      );
      toast({
        title: "Error occurred in initialising product unit manager.",
        description:
          "Please try again later & contact support if issue persists.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newProductUnits = productUnitMgr.newProductUnits;
    const newInventoryUnitIds = productUnitMgr.getNewInventoryUnitIds();
    const removedInventoryUnitIds = productUnitMgr.getRemovedInventoryUnitIds();
    if (
      !(
        newProductUnits.length ||
        newInventoryUnitIds.length ||
        removedInventoryUnitIds.length
      )
    ) {
      toast({
        title: "No units to add or modify.",
        description: "Please add or modify units before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const errors = {
      newProductUnits: null,
      newInventoryUnitIds: null,
      removedInventoryUnitIds: null,
    };

    if (newInventoryUnitIds.length) {
      if (!organisationId) {
        toast({
          title: "No organisation ID.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      postInventoryItemList({
        organisationId,
        inventoryItems: newInventoryUnitIds.map((productUnitId) => ({
          product_id: selectedProduct.id,
          product_unit_id: productUnitId,
          organisation_id: organisationId,
        })),
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["newInventoryUnitIds"] = error;
        });
    }

    if (removedInventoryUnitIds.length) {
      if (!organisationId) return;
      deleteInventoryItemListFromUnitIdList({
        organisationId,
        productUnitIds: removedInventoryUnitIds,
      })
        .unwrap()
        .then(() => {})
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          errors["removedInventoryUnitIds"] = error;
        });
    }

    postProductUnitList({
      productId: selectedProduct.id,
      productUnits: newProductUnits,
    })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        errors["newProductUnits"] = error;
      });
    if (
      errors["newProductUnits"] ||
      errors["newInventoryUnitIds"] ||
      errors["removedInventoryUnitIds"]
    ) {
      toast({
        title: "Units failed to create or modify.",
        description:
          "Please check your inputs and try again. If the issue persists please contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Units created/updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const createNewProduct = () => {
    if (!productName || productName.length === 0) {
      toast({
        title: "Product name is required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!units || units.length === 0) {
      toast({
        title: "Unit details are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    postProduct({
      name: fullProductName,
      category: "PRODUCE",
      product_units:
        units.length > 0
          ? units
          : [
              {
                unit_label: unitLabel,
                unit_quantity: unitQuantity,
                unit_measure: unitMeasure,
                unit_system: unitSystem,
              },
            ],
    } as Product)
      .unwrap()
      .then(() => {
        toast({
          title: "Product created with specified unit(s).",
          description: "You can view this in the Products list.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        clearForm();
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Product failed to create.",
          description:
            "Please try again later & contact support if issue persists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const clearForm = () => {
    setProductName("");
    setVariety("");
    setFullProductName("");
    setSelectedProduct(null);
    setIsCreatingProduct(false);
    setUnitLabel("");
    setUnitQuantity(1);
    setUnitMeasure("lb");
    setUnitSystem("IMPERIAL_US");
    setIsAddingNewUnit(false);
    setUnits([]);
  };

  useEffect(() => {
    clearForm();
  }, [onClose]);

  const addUnit = () => {
    if (unitQuantity && unitMeasure) {
      setUnits([
        ...units,
        {
          unit_label: unitLabel || "",
          unit_quantity: Number(unitQuantity),
          unit_measure: unitMeasure,
          unit_system: unitSystem,
        },
      ]);
      setUnitLabel("");
      setUnitQuantity(1);
      setUnitMeasure("lb");
      setUnitSystem("IMPERIAL_US");
    } else {
      toast({
        title: "Unit details are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Add product</ModalHeader>
        <ModalBody>
          <Flex flexDirection={["column", "row"]} gap={4}>
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Input
                placeholder={"Product name..."}
                value={productName}
                onChange={(e) => {
                  const nameUpdate = capitalizeWords(e.target.value);
                  setProductName(nameUpdate);
                  updateFullProductName(nameUpdate, variety);
                }}
                isDisabled={Boolean(selectedProduct || isCreatingProduct)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Variety (optional)</FormLabel>
              <Input
                placeholder={"Variety name..."}
                value={variety}
                onChange={(e) => {
                  const varietyUpdate = capitalizeWords(e.target.value);
                  setVariety(varietyUpdate);
                  updateFullProductName(productName, varietyUpdate);
                }}
                isDisabled={Boolean(selectedProduct || isCreatingProduct)}
              />
            </FormControl>
          </Flex>
          {isSearching && <Spinner />}
          {fullProductName.length > 0 &&
            !selectedProduct &&
            !isCreatingProduct &&
            searchResults &&
            searchResults.length > 0 &&
            showSearchResults && (
              <Box mt={4}>
                <Text fontWeight="bold">Select from existing products:</Text>
                <List spacing={3}>
                  {searchResults.map((product) => (
                    <ListItem key={product.id}>
                      <Button
                        onClick={() => handleSelectExistingProduct(product)}
                        variant="outline"
                      >
                        {product.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          {productName && !selectedProduct && !isCreatingProduct && (
            <Box mt={4}>
              <Text fontWeight="bold">Create new product:</Text>
              <Tooltip
                label={
                  matchInResults
                    ? "Exact match in suggested products - please select"
                    : ""
                }
                aria-label={
                  matchInResults
                    ? "Exact match in suggested products - please select"
                    : ""
                }
              >
                <Button
                  onClick={() => {
                    setIsCreatingProduct(true);
                    setIsAddingNewUnit(true);
                  }}
                  variant="outline"
                  isDisabled={matchInResults}
                >
                  {`Create new product: '${fullProductName}'?`}
                </Button>
              </Tooltip>
            </Box>
          )}
          {fullProductName.length > 0 && selectedProduct && (
            <>
              <Box mt={4}>
                <Text fontWeight="bold">Add Units:</Text>
                {selectedProduct && productUnitMgr && (
                  <ProductUnitManager
                    product={selectedProduct}
                    productUnitMgr={productUnitMgr}
                  />
                )}

                {/*<Text fontWeight="bold">Available Units:</Text>*/}
                {/*{availableUnits.length > 0 ? (*/}
                {/*  <Select*/}
                {/*    value={isAddingNewUnit ? "add_new" : unitLabel}*/}
                {/*    onChange={(e) => {*/}
                {/*      if (e.target.value === "add_new") {*/}
                {/*        setIsAddingNewUnit(true);*/}
                {/*      } else {*/}
                {/*        setIsAddingNewUnit(false);*/}
                {/*        const selectedUnit = availableUnits.find(*/}
                {/*          (unit) => unit.unit_label === e.target.value,*/}
                {/*        );*/}
                {/*        if (selectedUnit) {*/}
                {/*          setUnitLabel(selectedUnit.unit_label);*/}
                {/*          setUnitQuantity(selectedUnit.unit_quantity);*/}
                {/*          setUnitMeasure(selectedUnit.unit_measure);*/}
                {/*          setUnitSystem(selectedUnit.unit_system);*/}
                {/*        }*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {availableUnits.map((unit) => (*/}
                {/*      <option key={unit.id} value={unit.unit_label}>*/}
                {/*        {`${unit.unit_label} (${unit.unit_quantity} ${unit.unit_measure})`}*/}
                {/*      </option>*/}
                {/*    ))}*/}
                {/*    <option value="add_new">+ Add new unit</option>*/}
                {/*  </Select>*/}
                {/*) : (*/}
                {/*  <Text>No units available for this product.</Text>*/}
                {/*)}*/}
              </Box>
            </>
          )}
          {isAddingNewUnit && (
            <Box mt={4}>
              <Text fontWeight="bold">Add New Unit:</Text>
              <HStack spacing={4} mt={2}>
                <FormControl>
                  <FormLabel>Unit Label</FormLabel>
                  <Input
                    placeholder={"Unit label..."}
                    value={unitLabel}
                    onChange={(e) => setUnitLabel(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Unit Quantity</FormLabel>
                  <NumberInput
                    value={unitQuantity}
                    min={0.00001}
                    step={0.1}
                    precision={3}
                    onChange={(valueAsString) =>
                      setUnitQuantity(Number(valueAsString))
                    }
                  >
                    <NumberInputField placeholder="Quantity" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Unit Measure</FormLabel>
                  <Select
                    value={unitMeasure}
                    onChange={(e) => handleMeasureChange(e.target.value)}
                  >
                    <option value="lb">lb</option>
                    <option value="oz">oz</option>
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                    <option value="unit">each</option>
                  </Select>
                </FormControl>
              </HStack>
              <Button mt={2} onClick={addUnit}>
                Add Unit
              </Button>
            </Box>
          )}
          {units && units.length > 0 && (
            <Box mt={4}>
              <Text fontWeight="bold">Added Units:</Text>
              <List>
                {units.map((unit, index) => (
                  <ListItem key={index}>{`${getProductUnitLabel(
                    unit,
                  )}`}</ListItem>
                ))}
              </List>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={handleAddProduct}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button onClick={clearForm}>Clear</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
