import React, { useMemo } from "react";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { EventSalesReconciliationItem } from "../../types";
import { Box, Text } from "@chakra-ui/react";
import EditModal from "./EditModal";
import { useVirtualizer } from "@tanstack/react-virtual";

interface StockReconciliationInputProps {
  reconciliationItems: EventSalesReconciliationItem[];
  setReconciliationItems: (items: EventSalesReconciliationItem[]) => void;
  isOpening?: boolean;
  enableAllEditing?: boolean;
  height?: number;
  productsInStock?: Set<number>;
  currentStep?: number;
}

const StockReconciliationListInput = ({
  reconciliationItems = [],
  setReconciliationItems,
  currentStep,
}: StockReconciliationInputProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] =
    React.useState<EventSalesReconciliationItem | null>(null);
  const [currentItemIndex, setCurrentItemIndex] = React.useState<number>(0);

  const handleQuantityChange = (updatedItem: EventSalesReconciliationItem) => {
    if (
      [
        updatedItem.opening_quantity,
        updatedItem.closing_quantity,
        updatedItem.sold_quantity,
        updatedItem.wasted_quantity,
      ].some(Boolean)
    ) {
      const newItems = reconciliationItems.map((item) =>
        item.product_unit_id === updatedItem.product_unit_id
          ? updatedItem
          : item,
      );
      setReconciliationItems(newItems);
    }
    const nextIndex = currentItemIndex + 1;
    if (nextIndex < reconciliationItems.length) {
      setCurrentItemIndex(nextIndex);
      setSelectedItem(reconciliationItems[nextIndex]);
    } else {
      setIsModalOpen(false);
    }
  };

  const columns = useMemo<ColumnDef<EventSalesReconciliationItem>[]>(
    () => [
      {
        accessorKey: "product",
        header: "Product",
        cell: (info) => (
          <Box py={2} height="40px" display="flex" alignItems="center">
            <Text noOfLines={2} title={info.getValue()?.name}>
              {info.getValue()?.name}
            </Text>
          </Box>
        ),
        size: 224,
        enableResizing: true,
      },
      {
        accessorKey: "product_unit",
        header: "Unit",
        cell: (info) => (
          <Text noOfLines={1} title={getProductUnitLabel(info.getValue())}>
            {getProductUnitLabel(info.getValue())}
          </Text>
        ),
        size: 150,
      },
      {
        accessorKey: "opening_quantity",
        header: "Opening quantity",
        cell: (info) => info.getValue(),
        size: 120,
        meta: {
          isHighlighted: currentStep === 1,
        },
      },
      {
        accessorKey: "closing_quantity",
        header: "Closing quantity",
        cell: (info) => info.getValue(),
        size: 120,
        meta: {
          isHighlighted: currentStep === 2,
        },
      },
      {
        accessorKey: "sold_quantity",
        header: "Sold quantity",
        cell: (info) => info.getValue(),
        size: 120,
        meta: {
          isHighlighted: currentStep === 2,
        },
      },
      {
        accessorKey: "wasted_quantity",
        header: "Wasted quantity",
        cell: (info) => info.getValue(),
        size: 120,
        meta: {
          isHighlighted: currentStep === 2,
        },
      },
      {
        accessorKey: "variance_quantity",
        header: "Variance",
        cell: (info) => info.getValue(),
        size: 120,
        meta: {
          isHighlighted: currentStep === 2,
        },
      },
    ],
    [currentStep],
  );

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data: reconciliationItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 60, // Increase the row height
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  });

  return (
    <>
      <Box
        height="416px"
        width="100%"
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        overflow="auto"
        ref={tableContainerRef}
        boxShadow="sm"
        bg="white"
        position="relative"
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              background: "white",
              zIndex: 1,
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                style={{ display: "flex", width: "100%" }}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      padding: "12px 8px",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "1px solid #e0e0e0",
                      borderBottom: "2px solid #e0e0e0",
                      backgroundColor: header.column.columnDef.meta
                        ?.isHighlighted
                        ? "#e6f7ff"
                        : "white",
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: "block",
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: "relative",
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr
                  key={row.id}
                  style={{
                    display: "flex",
                    position: "absolute",
                    transform: `translateY(${virtualRow.start}px)`,
                    width: "100%",
                    height: "60px",
                    backgroundColor:
                      virtualRow.index % 2 === 0 ? "#f8f9fa" : "white",
                    borderBottom: "1px solid #e0e0e0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedItem(row.original);
                    setCurrentItemIndex(virtualRow.index);
                    setIsModalOpen(true);
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          width: cell.column.getSize(),
                          padding: "8px",
                          textAlign: "center",
                          borderRight: "1px solid #e0e0e0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          backgroundColor: cell.column.columnDef.meta
                            ?.isHighlighted
                            ? "#e6f7ff"
                            : "inherit",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      {selectedItem && (
        <EditModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          item={selectedItem}
          onSave={handleQuantityChange}
          currentStep={currentStep ?? 0}
          onPrevious={() => {
            if (currentItemIndex > 0) {
              setCurrentItemIndex(currentItemIndex - 1);
              setSelectedItem(reconciliationItems[currentItemIndex - 1]);
            } else {
              setIsModalOpen(false);
            }
          }}
        />
      )}
    </>
  );
};

export default StockReconciliationListInput;
