import { Address, InvoiceItem } from "../../types";
import { useEffect, useState, useMemo } from "react";
import {
  Box,
  Checkbox,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import DataTable from "../../components/DataTable/DataTable";
import * as React from "react";
import EditableColumn from "../../components/DataTable/EditableColumn";
import {
  getDiscountPercentage,
  getInvoiceCredit,
  getInvoiceTotal,
  getItemSalesPrice,
} from "../../utils/invoiceUtils";
import AddressSearchOrCreate from "../../components/AddressSearchOrCreate/AddressSearchOrCreate";

interface InvoiceSummaryProps {
  supplierOrgId: number;
  supplierLocationId: number;
  buyerOrgId: number;
  buyerLocationId: number;
  invoiceItems: InvoiceItem[];
  invoiceDate: string;
  deliveryDate: string;
  invoiceTotal: number;
  totalCredit: number;
  buyerPONumber?: string;
  deliveryAddressId?: number;
  isPickup: boolean;
  deliveryNote: string;
  setInvoiceItems: (value: InvoiceItem[]) => void;
  setInvoiceDate: (value: string) => void;
  setDeliveryDate: (value: string) => void;
  setInvoiceTotal: (value: number) => void;
  setTotalCredit: (value: number) => void;
  setBuyerPONumber: (value: string) => void;
  setDeliveryAddressId: (value: number | undefined) => void;
  setIsPickup: (value: boolean) => void;
  setDeliveryNote: (value: string) => void;
  isSupplierView?: boolean;
  supplierOrCustomerName: string;
  isEditable?: boolean;
}

const StackEntry = ({ children }: { children: React.ReactNode[] }) => (
  <VStack alignItems={"start"}>
    {children && children.map((child, index) => <div key={index}>{child}</div>)}
  </VStack>
);

export default function InvoiceSummary({
  supplierOrgId,
  supplierLocationId,
  buyerOrgId,
  buyerLocationId,
  invoiceItems,
  invoiceDate,
  deliveryDate,
  invoiceTotal,
  totalCredit,
  buyerPONumber,
  deliveryAddressId,
  isPickup,
  deliveryNote,
  setInvoiceItems,
  setInvoiceDate,
  setDeliveryDate,
  setInvoiceTotal,
  setTotalCredit,
  setBuyerPONumber,
  setDeliveryAddressId,
  setDeliveryNote,
  setIsPickup,
  isSupplierView = false,
  supplierOrCustomerName,
  isEditable = true,
}: InvoiceSummaryProps) {
  const toast = useToast();
  const [enableDiscounts, setEnableDiscounts] = useState<boolean>(false);

  useEffect(() => {
    for (const invoiceItem of invoiceItems) {
      if (
        invoiceItem.discounted_price &&
        invoiceItem.discounted_price !== invoiceItem.price
      ) {
        invoiceItem.discount_percent = getDiscountPercentage(invoiceItem);
        setEnableDiscounts(true);
      }
    }
  }, [invoiceItems]);

  const handleDeliveryAddressChange = (address: Address | undefined) => {
    if (!address) {
      return;
    }
    setDeliveryAddressId(address.id);
  };

  const handleChangedInvoiceDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceDate(e.target.value.slice(0, 10));
  };

  const handleChangedDeliveryDate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDeliveryDate(e.target.value.slice(0, 10));
  };

  const handleInvoicedQuantityChange = (index: number, quantity: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          invoiced_quantity: quantity,
        };
        if (isSupplierView) {
          updatedInvoiceItem.received_quantity = quantity;
        }
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleReceivedQuantityChange = (index: number, quantity: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          received_quantity: quantity,
        };
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handlePriceChange = (index: number, price: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          price: price,
        };
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleEditDiscountPrice = (index: number, price: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          discounted_price: price,
          discount_percent: (1 - price / invoiceItem.price) * 100,
        };
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleEditDiscountPercent = (index: number, percent: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          discounted_price: (invoiceItem.price * (100 - percent)) / 100,
          discount_percent: percent,
        };
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleNotesChange = (index: number, notes: string) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
        };
        if (isSupplierView) {
          updatedInvoiceItem.supplier_notes = notes;
        } else {
          updatedInvoiceItem.buyer_notes = notes;
        }
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const columnHelper = createColumnHelper<InvoiceItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("product", {
        cell: (info) => info.getValue()?.name,
        header: "Product",
        size: 200,
      }),
      columnHelper.accessor("product_unit", {
        cell: (info) => getProductUnitLabel(info.getValue()),
        header: "Unit",
        size: 90,
      }),
      columnHelper.accessor(isSupplierView ? "supplier_notes" : "buyer_notes", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={info.getValue() || ""}
            inputType={"text"}
            setValue={(value: string) =>
              handleNotesChange(info.row.index, value)
            }
            isEditable={isEditable}
            width={"100px"}
          />
        ),
        header: "Notes",
        size: 150,
      }),
      columnHelper.accessor("requested_quantity", {
        cell: (info) => info.getValue(),
        header: "Requested quantity",
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("invoiced_quantity", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={info.getValue()}
            setValue={(value: string) =>
              handleInvoicedQuantityChange(info.row.index, Number(value))
            }
            isEditable={isEditable}
          />
        ),
        header: `Invoice${isSupplierView ? "" : "d"} qty`,
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("received_quantity", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={info.getValue()}
            setValue={(value: string) =>
              handleReceivedQuantityChange(info.row.index, Number(value))
            }
            isEditable={isEditable}
          />
        ),
        header: "Received qty",
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string) => {
              handlePriceChange(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={isEditable}
          />
        ),
        header: "Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discounted_price", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string) => {
              handleEditDiscountPrice(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={isEditable}
          />
        ),
        header: "Discounted Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discount_percent", {
        cell: (info) => (
          <EditableColumn
            customStyles={{ justifyContent: "flex-end" }}
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string) => {
              handleEditDiscountPercent(info.row.index, Number(value));
            }}
            label={"%"}
            isEditable={isEditable}
          />
        ),
        header: "Discount %",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: `Invoice${isSupplierView ? "" : "d"} total`,
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("credit", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Credit total",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
    ],
    [
      isSupplierView,
      isEditable,
      handleNotesChange,
      handleInvoicedQuantityChange,
      handleReceivedQuantityChange,
      handlePriceChange,
      handleEditDiscountPrice,
      handleEditDiscountPercent,
    ],
  );

  useEffect(() => {
    if (invoiceItems && invoiceItems.length > 0) {
      const newInvoiceTotal = invoiceItems.reduce((total, item) => {
        return total + getItemSalesPrice(item) * item.invoiced_quantity;
      }, 0);
      const newTotalCredit = invoiceItems.reduce((total, item) => {
        return (
          total +
          getItemSalesPrice(item) *
            (item.invoiced_quantity - item.received_quantity)
        );
      }, 0);
      setInvoiceTotal(newInvoiceTotal);
      setTotalCredit(newTotalCredit);
    } else {
      setInvoiceTotal(0);
      setTotalCredit(0);
    }
  }, [invoiceItems]);

  return (
    <Box p={[2, 4]} minHeight="600px">
      <Heading size={"md"}>Invoice Summary</Heading>
      <Spacer />
      <Stack
        direction={["column", "row"]}
        m={4}
        spacing={4}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            {isSupplierView ? "Customer" : "Supplier"}:{" "}
          </b>
          {supplierOrCustomerName}
        </StackEntry>
        <StackEntry>
          <b>Invoice date:</b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="date"
            value={invoiceDate}
            onChange={handleChangedInvoiceDate}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            Deliver{isSupplierView ? "y" : "ed"} date:{" "}
          </b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="date"
            value={deliveryDate}
            onChange={handleChangedDeliveryDate}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Ext PO Number: </b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="text"
            value={buyerPONumber || ""}
            onChange={(e) => setBuyerPONumber(e.target.value)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Invoice total: </b>
          {`$ ${Number(invoiceTotal).toFixed(2)}`}
        </StackEntry>
        {!isSupplierView && (
          <StackEntry>
            <b style={{ marginRight: "8px" }}>Total credit: </b>
            {`$ ${Number(totalCredit).toFixed(2)}`}
          </StackEntry>
        )}
      </Stack>
      <Stack
        direction={["column", "row"]}
        m={4}
        spacing={4}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Delivery note: </b>
          <Input
            size="sm"
            marginLeft="4px"
            w={["100%", "150px"]}
            type="text"
            value={deliveryNote}
            onChange={(e) => setDeliveryNote(e.target.value)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Is Pickup: </b>
          <Checkbox
            isChecked={isPickup}
            onChange={(e) => setIsPickup(e.target.checked)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            {isPickup ? "Pickup at" : "Deliver to"}:
          </b>
          <AddressSearchOrCreate
            organisationId={isPickup ? supplierOrgId : buyerOrgId}
            locationId={isPickup ? supplierLocationId : buyerLocationId}
            setAddress={(address: Address | undefined) =>
              handleDeliveryAddressChange(address)
            }
            selectedAddressId={deliveryAddressId}
          />
        </StackEntry>
      </Stack>
      <Heading size={"md"} marginTop={"2rem"}>
        Invoice Items
      </Heading>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={[2, 4, 6]}
        overflowX="auto"
      >
        {isSupplierView && (
          <HStack w={"100%"} justifyContent={"flex-start"}>
            <Checkbox
              isChecked={enableDiscounts}
              onChange={(e) => {
                if (enableDiscounts) {
                  for (const invoiceItem of invoiceItems) {
                    if (
                      invoiceItem.discounted_price &&
                      invoiceItem.discounted_price !== invoiceItem.price
                    ) {
                      toast({
                        title: "Cannot disable discounts!",
                        description: "Please remove discounts to disable.",
                        status: "warning",
                        isClosable: true,
                      });
                      return;
                    }
                  }
                }
                setEnableDiscounts(e.target.checked);
              }}
              colorScheme="teal"
              size="md"
              alignSelf="flex-start"
            >
              Apply discounts
            </Checkbox>
          </HStack>
        )}
        <Box minWidth="1000px">
          <DataTable
            columnVisibility={{ requested_quantity: false }}
            data={invoiceItems}
            columns={columns}
          />
        </Box>
      </Box>
      <Stack
        direction={"row"}
        mt={4}
        spacing={4}
        justifyContent="space-between"
      >
        <StackEntry>
          <strong>Invoice Total:</strong>
          <strong>${invoiceTotal.toFixed(2)}</strong>
        </StackEntry>
        {totalCredit && (
          <StackEntry>
            <strong>Total Credit:</strong>
            <strong>${totalCredit.toFixed(2)}</strong>
          </StackEntry>
        )}
        {totalCredit && (
          <StackEntry>
            <strong>Balance Due:</strong>
            <strong>
              ${(Number(invoiceTotal) - Number(totalCredit)).toFixed(2)}
            </strong>
          </StackEntry>
        )}
      </Stack>
    </Box>
  );
}
