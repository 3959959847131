import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../DataTable/DataTable";
import { Product } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { ProductUnitMgr, ProductUnitSettings } from "./types";

const unitOptions = [
  { value: "lb", label: "lb" },
  { value: "oz", label: "oz" },
  { value: "g", label: "g" },
  { value: "kg", label: "kg" },
  { value: "unit", label: "each" },
  { value: "L", label: "L" },
  { value: "mL", label: "mL" },
  { value: "cL", label: "cL" },
  { value: "dL", label: "dL" },
];

interface ProductUnitManagerProps {
  product: Product;
  productUnitMgr: ProductUnitMgr;
}

const ProductUnitManager: React.FC<ProductUnitManagerProps> = ({
  product,
  productUnitMgr,
}) => {
  const toast = useToast();
  const [productUnitSettings, setProductUnitSettings] = useState<
    ProductUnitSettings[]
  >([]);
  const [newUnit, setNewUnit] = useState({
    label: "",
    quantity: 1,
    measure: "lb",
    system: "IMPERIAL_US",
  });
  const [isAddingUnit, setIsAddingUnit] = useState(false);

  useEffect(() => {
    setProductUnitSettings(productUnitMgr.productUnits);
  }, [productUnitMgr.productUnits]);

  const handleMeasureChange = (value: string) => {
    setNewUnit((prev) => ({
      ...prev,
      measure: value,
      system: ["oz", "lb"].includes(value)
        ? "IMPERIAL_US"
        : value === "unit"
        ? "QUANTITY"
        : "METRIC",
    }));
  };

  const columnHelper = createColumnHelper<ProductUnitSettings>();

  const columns = [
    columnHelper.accessor("unit_label", {
      cell: (info) => info.getValue(),
      header: "Label",
    }),
    columnHelper.accessor("unit_quantity", {
      cell: (info) => info.getValue(),
      header: "Quantity",
      meta: { isNumeric: true },
    }),
    columnHelper.accessor("unit_measure", {
      cell: (info) => {
        const value = info.getValue();
        const subUnit = info.row.original.sub_unit;
        return value === "unit"
          ? subUnit
            ? `each (${getProductUnitLabel(subUnit)})`
            : "each"
          : value;
      },
      header: "Measure",
    }),
    columnHelper.accessor("in_inventory", {
      cell: (info) => (
        <>
          <Checkbox
            isChecked={info.getValue()}
            onChange={(e) => {
              productUnitMgr.toggleProductUnitInventory(
                info.row.original,
                e.target.checked,
              );
              setProductUnitSettings([...productUnitMgr.productUnits]);
            }}
          />
          {info.row.original.is_new_unit && (
            <Badge colorScheme="green" ml="0.5rem">
              NEW
            </Badge>
          )}
        </>
      ),
      header: "Add to inventory?",
    }),
  ];

  const handleAddUnit = () => {
    if (!newUnit.quantity || !newUnit.measure || !newUnit.system) {
      toast({
        title: "Invalid unit",
        description: "Please fill out all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      productUnitMgr.addNewProductUnit({
        product_id: product.id,
        unit_label: newUnit.label,
        unit_quantity: newUnit.quantity,
        unit_measure: newUnit.measure,
        unit_system: newUnit.system,
      });
      setProductUnitSettings([...productUnitMgr.productUnits]);
    } catch (error) {
      toast({
        title: `Unit error: ${(error as Error).message}`,
        description:
          "Please check your inputs and try again. If this error shouldn't occur please contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <DataTable
        data={productUnitSettings}
        columns={columns}
        showAddRow
        onAddingRow={() => setIsAddingUnit(true)}
        addRowLabel="unit"
        addRowChildren={[
          <Input
            placeholder="Unit label..."
            onChange={(e) =>
              setNewUnit((prev) => ({ ...prev, label: e.target.value }))
            }
            value={newUnit.label}
          />,
          <NumberInput
            defaultValue={1}
            min={0.00001}
            onChange={(value) =>
              setNewUnit((prev) => ({ ...prev, quantity: Number(value) }))
            }
          >
            <NumberInputField placeholder="Quantity" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>,
          <Select
            value={newUnit.measure}
            onChange={(e) => handleMeasureChange(e.target.value)}
          >
            {unitOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>,
          <>
            <Checkbox isChecked={true} isDisabled />
            <Badge colorScheme="green" ml="0.5rem">
              NEW
            </Badge>
          </>,
        ]}
      />
      {isAddingUnit && <Button onClick={handleAddUnit}>Add Unit</Button>}
    </>
  );
};

export default ProductUnitManager;
