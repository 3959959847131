import { OrderItem, Price, ProductUnit } from "../../types";
import { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import * as React from "react";
import DataTable from "../../components/DataTable/DataTable";
import EditableColumn from "../../components/DataTable/EditableColumn";
import {
  getDiscountPercentage,
  getItemSalesPrice,
  getOrderTotal,
} from "../../utils/invoiceUtils";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
} from "../../redux/apiSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import { isEqual } from "lodash";

interface OrderSummaryProps {
  isSalesOrder?: boolean;
  orderItems: OrderItem[];
  productUnitPriceMap: Map<number, Price[]>;
  setOrderItems: (
    value: OrderItem[] | ((prevItems: OrderItem[]) => OrderItem[]),
  ) => void;
  setOrderTotal: (value: number) => void;
  preventItemEdits?: boolean;
  preventAddRow?: boolean;
  onChange: () => void;
  isReviewStep?: boolean;
}

export default function OrderSummary({
  isSalesOrder = false,
  orderItems,
  productUnitPriceMap,
  setOrderItems,
  setOrderTotal,
  preventItemEdits = true,
  preventAddRow = true,
  onChange,
  isReviewStep = false,
}: OrderSummaryProps): React.ReactElement {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: products } = useGetProductsQuery("");

  const { data: inventoryItems } = useGetInventoryItemsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [newProductId, setNewProductId] = useState<number | null | undefined>(
    null,
  );
  const [newItemProductName, setNewItemProductName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newProductUnitId, setNewProductUnitId] = useState<number | null>(null);
  const [newProductUnits, setNewProductUnits] = useState<ProductUnit[]>([]);
  const [newItemQuantity, setNewItemQuantity] = useState<number>(1);
  const [newItemCost, setNewItemCost] = useState<number | string>("");
  const [newItemTotal, setNewItemTotal] = useState<number | null>(null);

  const [enableDiscounts, setEnableDiscounts] = useState<boolean>(false);

  // Memoize the calculation of hasDiscounts and updatedOrderItems
  const { hasDiscounts, updatedOrderItems } = useMemo((): {
    hasDiscounts: boolean;
    updatedOrderItems: OrderItem[];
  } => {
    let hasDiscounts = false;
    const updatedItems = orderItems.map((orderItem) => {
      if (
        orderItem.discounted_price &&
        orderItem.discounted_price !== orderItem.price
      ) {
        hasDiscounts = true;
        return {
          ...orderItem,
          discount_percent: getDiscountPercentage(orderItem),
        } as OrderItem;
      }
      return {
        ...orderItem,
        discounted_price: orderItem.price,
        discount_percent: 0,
      } as OrderItem;
    });
    return { hasDiscounts, updatedOrderItems: updatedItems };
  }, [orderItems]);

  // Effect to update enableDiscounts and orderItems based on the memoized values
  useEffect(() => {
    if (hasDiscounts && !enableDiscounts) {
      setEnableDiscounts(true);
    }
    if (!isEqual(orderItems, updatedOrderItems)) {
      setOrderItems(updatedOrderItems);
    }
  }, [
    hasDiscounts,
    enableDiscounts,
    orderItems,
    updatedOrderItems,
    setOrderItems,
  ]);

  const updateOrderTotal = useCallback(() => {
    if (orderItems && orderItems.length > 0) {
      const newOrderTotal = orderItems.reduce((total, item) => {
        const unitPrice: number =
          getItemSalesPrice(item) ||
          productUnitPriceMap.get(item.product_unit_id)?.[0].price ||
          0;
        return total + unitPrice * item.quantity;
      }, 0);
      setOrderTotal(newOrderTotal);
    } else {
      setOrderTotal(0);
    }
  }, [orderItems, productUnitPriceMap, setOrderTotal]);

  useEffect(() => {
    updateOrderTotal();
  }, [updateOrderTotal]);

  useEffect(() => {
    let inventoryUnitIds = new Set();
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      inventoryUnitIds = new Set(
        inventoryItems.map((item) => item.product_unit_id),
      );
    } else {
      return;
    }
    if (products && products.length > 0 && newProductId) {
      const currentProduct = products.find(
        (product) => product.id === newProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter((unit) =>
          inventoryUnitIds.has(unit.id),
        );
        setNewProductUnits(filteredUnits);
        setNewProductUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description: "Please add units to proceed with order.",
            status: "warning",
            isClosable: true,
          });
        }
      } else {
        setNewProductUnits([]);
      }
    } else {
      setNewProductUnits([]);
    }
  }, [products, inventoryItems, newProductId]);

  const handleEdit = useCallback(
    (index: number, quantity: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              quantity: quantity,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditPrice = useCallback(
    (index: number, price: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              price: price,
            };
            if (updatedOrderItem.discounted_price) {
              updatedOrderItem.discount_percent =
                (1 -
                  updatedOrderItem.discounted_price / updatedOrderItem.price) *
                100;
            } else if (updatedOrderItem.discount_percent) {
              updatedOrderItem.discounted_price =
                (updatedOrderItem.price *
                  (100 - updatedOrderItem.discount_percent)) /
                100;
            } else {
              updatedOrderItem.discounted_price = price;
              updatedOrderItem.discount_percent = 0;
            }
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditDiscountPrice = useCallback(
    (index: number, price: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              discounted_price: price,
              discount_percent: (1 - price / orderItem.price) * 100,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleEditDiscountPercent = useCallback(
    (index: number, percent: number) => {
      onChange();
      setOrderItems((prevItems: OrderItem[]) =>
        prevItems.map((orderItem, i) => {
          if (i === index) {
            const updatedOrderItem = {
              ...orderItem,
              discounted_price: (orderItem.price * (100 - percent)) / 100,
              discount_percent: percent,
            };
            updatedOrderItem.total = getOrderTotal(updatedOrderItem);
            return updatedOrderItem;
          }
          return orderItem;
        }),
      );
    },
    [onChange, setOrderItems],
  );

  const handleAddNewItem = useCallback(() => {
    if (
      products &&
      newProductId &&
      newProductUnitId &&
      newItemQuantity &&
      newItemCost
    ) {
      const product = products.find((product) => product.id === newProductId);
      const productUnit = product?.product_units.find(
        (unit) => unit.id === newProductUnitId,
      );
      if (!product || !productUnit) {
        return;
      }
      const newItem: OrderItem = {
        product_id: newProductId,
        product: product,
        product_unit_id: newProductUnitId,
        product_unit: productUnit,
        quantity: newItemQuantity,
        price: Number(newItemCost),
        total: Number(newItemCost) * newItemQuantity,
      };
      setOrderItems((prevItems) => [...prevItems, newItem]);
      onChange();
      setNewItemQuantity(1);
      setNewItemCost("");
      setNewItemTotal(null);
      setNewProductId(null);
      setNewItemProductName("");
      setNewProductUnitId(null);
      setNewProductUnits([]);
    }
  }, [
    products,
    newProductId,
    newProductUnitId,
    newItemQuantity,
    newItemCost,
    onChange,
    setOrderItems,
  ]);

  const columnHelper = createColumnHelper<OrderItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("product", {
        cell: (info) => info.getValue()?.name,
        header: "Product",
        size: 200,
      }),
      columnHelper.accessor("product_unit", {
        cell: (info) => getProductUnitLabel(info.getValue()),
        header: "Unit",
        size: 90,
      }),
      columnHelper.accessor("quantity", {
        cell: (info) => (
          <EditableColumn
            inputId={"quantity-" + info.row.index}
            value={info.getValue()}
            setValue={(value: string | number) =>
              handleEdit(info.row.index, Number(value))
            }
            isEditable={!preventItemEdits}
          />
        ),
        header: "Requested Quantity",
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) => (
          <EditableColumn
            inputId={"price-" + info.row.index}
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string | number) => {
              handleEditPrice(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discounted_price", {
        cell: (info) => (
          <EditableColumn
            inputId={"discounted-price-" + info.row.index}
            value={Number(info.getValue() || info.row.original.price).toFixed(
              2,
            )}
            setValue={(value: string | number) => {
              handleEditDiscountPrice(info.row.index, Number(value));
            }}
            label={"$"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Discount Price",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("discount_percent", {
        cell: (info) => (
          <EditableColumn
            inputId={"discount-percent-" + info.row.index}
            value={Number(info.getValue() || 0).toFixed(2)}
            setValue={(value: string | number) => {
              handleEditDiscountPercent(info.row.index, Number(value));
            }}
            label={"%"}
            isEditable={!preventItemEdits}
            customStyles={{ justifyContent: "flex-end" }}
            width="80px"
          />
        ),
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Discount %",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        sortingFn: (a, b, columnId) =>
          Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
        header: "Total",
        size: 120,
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
    ],
    [
      columnHelper,
      preventItemEdits,
      handleEdit,
      handleEditPrice,
      handleEditDiscountPrice,
      handleEditDiscountPercent,
    ],
  );

  return (
    <div>
      {isSalesOrder && (
        <>
          {(!isReviewStep || (isReviewStep && enableDiscounts)) && (
            <Checkbox
              isChecked={enableDiscounts}
              onChange={(e) => {
                if (!isReviewStep) {
                  if (enableDiscounts) {
                    for (const orderItem of orderItems) {
                      if (
                        orderItem.discounted_price &&
                        orderItem.discounted_price !== orderItem.price
                      ) {
                        toast({
                          title: "Cannot disable discounts!",
                          description: "Please remove discounts to disable.",
                          status: "warning",
                          isClosable: true,
                        });
                        return;
                      }
                    }
                  }
                  setEnableDiscounts(e.target.checked);
                }
              }}
              colorScheme="teal"
              size="md"
              alignSelf="flex-start"
              isDisabled={isReviewStep}
              mb={2}
            >
              Apply discounts
            </Checkbox>
          )}
        </>
      )}
      <Box
        overflowX="auto"
        maxWidth="100%"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Box minWidth="1000px">
          <DataTable
            data={orderItems}
            columns={columns}
            columnVisibility={
              !enableDiscounts
                ? { discounted_price: false, discount_percent: false }
                : {}
            }
            showAddRow={!preventAddRow}
            addRowLabel={"item"}
            addRowNextFocus={"new-item-description"}
            addRowChildren={[
              <InventoryProductSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setProductFilterId={setNewProductId}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    document.getElementById("new-item-unit")?.focus();
                  }
                }}
              />,
              <AddUnitSelectField
                focusId="new-item-unit"
                selectedProductId={newProductId}
                selectedProductName={newItemProductName}
                selectedUnitId={newProductUnitId}
                setSelectedUnitId={setNewProductUnitId}
                units={newProductUnits}
                setUnits={setNewProductUnits}
                addUnitsEnabled={isSalesOrder}
              />,
              <Input
                id={"new-item-quantity"}
                type="number"
                value={newItemQuantity}
                onChange={(e) => setNewItemQuantity(Number(e.target.value))}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    // handleAddNewItem();
                    document.getElementById("new-item-cost")?.focus();
                  }
                }}
              />,
              <InputGroup>
                <InputLeftElement>$</InputLeftElement>
                <Input
                  id={"new-item-cost"}
                  type="number"
                  value={newItemCost}
                  onChange={(e) => setNewItemCost(Number(e.target.value))}
                  isDisabled={!isSalesOrder}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Tab") {
                      handleAddNewItem();
                      document.getElementById("new-item-description")?.focus();
                    }
                  }}
                />
              </InputGroup>,
              <Text>${Number(newItemTotal).toFixed(2)}</Text>,
            ]}
          />
        </Box>
      </Box>
    </div>
  );
}
